import Box from '@spraoi/base/Box';
import { borderRadius } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';

const IconFigure = ({ as, iconWidth, size, svg, sx, text, png }) => (
  <Box
    as={as}
    sx={{
      alignItems: 'center',
      bg: 'offWhite',
      borderRadius: 3,
      boxShadow: 3,
      color: 'text.subtle',
      display: 'flex',
      flexDirection: 'column',
      fontSize: 0,
      height: size,
      justifyContent: 'center',
      letterSpacing: 1,
      lineHeight: 1,  
      textAlign: 'center',
      textTransform: 'uppercase',
      width: size,
      ...sx,
    }}
  >
    <Box sx={{ mb: 'auto', mx: 'auto', width: iconWidth }}>
      {png 
        ? <Box sx={{ height: '100%' }}>
            <img 
              src={png} 
              alt={png}
              style={{ 
                maxWidth: '100%',
                maxHeight: '100%',
                borderRadius: '3px',
              }} 
            />
          </Box> 
        : <Icon svg={svg} sx={{ height: '100%' }} />
      }
    </Box>
  </Box>
);

IconFigure.propTypes = {
  as: PropTypes.node,
  iconWidth: PropTypes.string,
  size: PropTypes.string,
  svg: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
  text: PropTypes.node.isRequired,
};

IconFigure.defaultProps = {
  as: 'div',
  iconWidth: '7rem',
  size: '9.2rem',
  sx: {},
};

export default IconFigure;
